import React, { useState, useEffect } from 'react'
import Header from "../components/header"
import HeaderMenu from "../components/header-menu"
import Footer from "../components/footer"
import { Link } from 'gatsby'
import { navigate } from "gatsby"
import { isLoggedIn, getUser,logout } from "../services/auth"
import axios, { post } from 'axios';


const FileForm = props => {
  const [pdf, setPdf] = useState([]);
  function signOut(event){
    event.preventDefault();
      logout()
      navigate("/login")        
  }
   
  const onChange1 =(e) => {
    console.log("files1="+e.target.files[0])
    setPdf(e.target.files[0]);
  }
 
  const onSubmitForm=()=>{
      const url = "/auth/uploadFiles";
      const formData = new FormData();
      formData.append('file', pdf);
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
      post(url, formData, config).then((response) => {
          console.log(response.data);
      })
  }

  
  useEffect( () => {
    //  this.onChange = this.onChange.bind(this)
    // this.fileUpload = this.fileUpload.bind(this)
  }, []);

  return (
    <span>
      <div className="field-wrapper">
          <input className="field-input dirty" type="file" name="pdfFile" onChange={onChange1} />
          <label htmlFor="pesmig">Pdf File </label>
      </div>
      <div className="steps-nav">
          <button className="btn-secondary btn--xs steps-nav-init"  onClick={onSubmitForm}>Confirmar</button>
      </div> 
    </span>
  )

}

export default FileForm